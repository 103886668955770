import { MessageOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import { ROUTE_CONTACT_US_UNREGISTER } from "navigation/CONSTANTS";
import React from "react";
import { useHistory } from "react-router";

const ContactUsWidget = () => {
  const history = useHistory();
  return (
    <div
      className="contact-rect"
    >
      <div>
        <span
          className="custom-button contact-us-contact-us-button"
          onClick={() => {
            history.push(ROUTE_CONTACT_US_UNREGISTER);
          }}
        >
          Contact Us <MessageOutlined />
        </span>
      </div>
      <div
        className="landing-description1-text"
        style={{ marginTop: 20, width: "100%", textAlign: "center" }}
      >
        <Row justify={"center"}>
          <Col style={{ maxWidth: 600, padding: 10 }}>
            Let us know how we can serve to provide you a personalized
            experience. Fill in your contact details and we will take it from
            there
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ContactUsWidget;

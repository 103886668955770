import React from "react";
import { Col, Row } from "antd";
const HeaderWidget = () => {
  return (
    <div className="login-part-header-container">
      <Row style={{ width: "100%" }}>
        <Col xs={24} md={12}>
          <div style={{ maxWidth: 500 }}>
            <div className="landing-header2-text">
            Welcome to the Kalae Cafe Shop
            </div>
          </div>
        </Col>
        <Col xs={24} md={12}> 
          <div
            className="landing-description1-text"
          >
            1. Please see available cafe shop info. <br />
            2. Please add item to QTY for Ingredient and Recipe. <br />
            3. You can make paypal or cash payment <br/>
            4. Enter your name when you make payment. <br/>
            5. You will get your order asap.  <br/>
            We are the best welcome for you. Thank you.
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default HeaderWidget;
